export const useMaasStoresList = async () => {
  const { apiInstance } = useShopwareContext();

  return await useLazyAsyncData(
    "maasStoreList",
    async () => {
      const maasStore = await apiInstance.invoke.get("/store-api/maas-stores-list");

      return maasStore.data;
    },
    {
      immediate: true,
    }
  );
};
